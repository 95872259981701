module.exports = [{
      plugin: require('/Users/linmic/dev/enl/enl.design/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/linmic/dev/enl/enl.design/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-121978561-3","optimizeId":"GTM-KFZPTPQ","head":false},
    },{
      plugin: require('/Users/linmic/dev/enl/enl.design/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
